<template>
  <div class="help-faq">
    <div class="help-faq__search">
      <app-input
        id="faqSearch"
        v-model="searchText"
        class="help-faq__search-input"
        :label="$t('type_to_search')"
      />
    </div>
    <span v-if="!searchResults" class="error-message">{{ $t('no_search_results') }}</span>
    <div v-show="searchResults" id="faqQuestions">
      <div class="help-faq__block">
        <h2 class="light-primary-title">
          {{ $t('common_questions') }}
        </h2>
        <div class="help-faq__questions">
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.application_possibilities_summary')" />
            </template>
            <p v-html="$t('help_faq.application_possibilities_text')" />
          </app-accordion>
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.where_to_start_summary')" />
            </template>
            <p v-html="$t('help_faq.where_to_start_text')" />
          </app-accordion>
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.how_to_share_summary')" />
            </template>
            <p v-html="$t('help_faq.how_to_share_text')" />
          </app-accordion>
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.how_qr_works_summary')" />
            </template>
            <p v-html="$t('help_faq.how_qr_works_text')" />
          </app-accordion>
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.customize_event_summary')" />
            </template>
            <p v-html="$t('help_faq.customize_event_text')" />
          </app-accordion>
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.enable_disable_gallery_summary')" />
            </template>
            <p v-html="$t('help_faq.enable_disable_gallery_text')" />
          </app-accordion>
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.enable_disable_public_summary')" />
            </template>
            <p v-html="$t('help_faq.enable_disable_public_text')" />
          </app-accordion>
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.is_slideshow_possible_summary')" />
            </template>
            <p v-html="$t('help_faq.is_slideshow_possible_text')" />
          </app-accordion>
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.do_guest_provide_details_summary')" />
            </template>
            <p v-html="$t('help_faq.do_guest_provide_details_text')" />
          </app-accordion>
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.where_is_media_stored_summary')" />
            </template>
            <p v-html="$t('help_faq.where_is_media_stored_text')" />
          </app-accordion>
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.which_connector_to_choose_summary')" />
            </template>
            <p v-html="$t('help_faq.which_connector_to_choose_text')" />
          </app-accordion>
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.connect_to_google_drive_summary')" />
            </template>
            <p v-html="$t('help_faq.connect_to_google_drive_text')" />
          </app-accordion>
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.manual_images_in_google_drive_folder_summary')" />
            </template>
            <p v-html="$t('help_faq.manual_images_in_google_drive_folder_text')" />
          </app-accordion>
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.dropbox_slow_summary')" />
            </template>
            <p v-html="$t('help_faq.dropbox_slow_text')" />
          </app-accordion>
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.upload_limits_summary')" />
            </template>
            <p v-html="$t('help_faq.upload_limits_text')" />
          </app-accordion>
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.upload_videos_summary')" />
            </template>
            <p v-html="$t('help_faq.upload_videos_text')" />
          </app-accordion>
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.how_to_delete_summary')" />
            </template>
            <p v-html="$t('help_faq.how_to_delete_text')" />
          </app-accordion>
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.how_to_sort_summary')" />
            </template>
            <p v-html="$t('help_faq.how_to_sort_text')" />
          </app-accordion>
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.when_will_drive_expire_summary')" />
            </template>
            <p v-html="$t('help_faq.when_will_drive_expire_text')" />
          </app-accordion>
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.what_happens_after_event_close_summary')" />
            </template>
            <p v-html="$t('help_faq.what_happens_after_event_close_text')" />
          </app-accordion>
        </div>
      </div>

      <div class="help-faq__block">
        <h2 class="light-primary-title">
          {{ $t('how_to.videos') }}
        </h2>
        <div class="help-faq__questions">
          <app-accordion
            v-for="videoFaqItem in videoFaqItems"
            :key="videoFaqItem.title"
            :ref="(el) => (videoFaqItemsRefs[videoFaqItem.title] = el)"
            @toggle="handleVideoFaqItemToggle(videoFaqItem.title)"
          >
            <template #summary>
              <p v-html="$t(videoFaqItem.title)" />
            </template>
            <object
              v-if="mappedVideoFaqItemsVisible[videoFaqItem.title]"
              class="how-to__video"
              :data="videoFaqItem.videoUrl"
            />
          </app-accordion>
        </div>
      </div>

      <div class="help-faq__block">
        <h2 class="light-primary-title">
          {{ $t('troubleshooting') }}
        </h2>
        <div class="help-faq__questions">
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.google_drive_disconnected_summary')" />
            </template>
            <p v-html="$t('help_faq.google_drive_disconnected_text')" />
          </app-accordion>
          <app-accordion>
            <template #summary>
              <p v-html="$t('help_faq.what_do_to_when_errors_occur_summary')" />
            </template>
            <p v-html="$t('help_faq.what_do_to_when_errors_occur_text')" />
          </app-accordion>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import debounce from 'lodash.debounce'
  import { watch } from 'vue'

  const searchText = ref(null)
  const searchResults = ref(true)
  const onSearchTextDebounce = debounce(() => doSearch(), 500)

  watch(searchText, onSearchTextDebounce)

  const videoFaqItems = [
    {
      title: 'how_to.event_page_title',
      videoUrl: 'https://www.youtube.com/embed/Ecee2vfPgNo?si=-2LX1Pfa6fjUrzuL&rel=0"',
    },
    {
      title: 'how_to.event_settings_title',
      videoUrl: 'https://www.youtube.com/embed/9V_od-221f4?si=YLY_oeZ2ieb2J8ai&rel=0"',
    },
    {
      title: 'how_to.use_connector_title',
      videoUrl: 'https://www.youtube.com/embed/nmjzXPdCrzo?si=yJZcGW4nE_pjl-ey&rel=0"',
    },
    {
      title: 'how_to.photo_management_title',
      videoUrl: 'https://www.youtube.com/embed/yplcaYPRUqs?si=PrmJnH566g0ABt-l&rel=0"',
    },
    {
      title: 'how_to.present_mode',
      videoUrl: 'https://www.youtube.com/embed/B3v-enCxjz4?si=x7oQ1oDUPGXRkyU0&rel=0"',
    },
  ]
  const mappedVideoFaqItemsVisible = videoFaqItems.reduce((acc, videoFaqItem) => {
    acc[videoFaqItem.title] = false

    return acc
  }, {})
  const videoFaqItemsRefs = ref({})

  function handleVideoFaqItemToggle(videoFaqItemTitle) {
    if (mappedVideoFaqItemsVisible[videoFaqItemTitle]) {
      return
    }

    mappedVideoFaqItemsVisible[videoFaqItemTitle] = true
    setTimeout(() => videoFaqItemsRefs.value[videoFaqItemTitle].setExpandedHeight(), 100)
  }

  function doSearch() {
    const faqs = document.getElementsByClassName('app-accordion')
    let amountSearchResults = 0

    const searchTerm = searchText.value?.toLowerCase()
    const regex = new RegExp(`(${searchTerm})`, 'gi')
    const minSearchTextLength = 3

    if (!faqs.length) return

    for (let i = 0; i < faqs.length; i++) {
      const faqElement = faqs[i]
      faqElement.classList?.remove('hidden')
      const titleElement = faqElement.getElementsByClassName('app-accordion__button-content')?.[0]
      const textElement = faqElement.getElementsByClassName('app-accordion__content')?.[0]
      titleElement.innerHTML = titleElement.innerHTML.replace(/<span class="highlight">|<\/span>/g, '')
      textElement.innerHTML = textElement.innerHTML.replace(/<span class="highlight">|<\/span>/g, '')
      textElement.classList.remove('app-accordion__content--expanded')

      if (searchTerm.length > minSearchTextLength) {
        const titleMatch = titleElement?.innerHTML?.toLowerCase().includes(searchTerm)
        const textMatch = textElement?.innerHTML?.toLowerCase().includes(searchTerm)

        if (textMatch) {
          textElement.classList.add('app-accordion__content--expanded')
        } else {
          textElement.classList.remove('app-accordion__content--expanded')
        }

        if (!titleMatch && !textMatch) {
          faqElement.classList?.add('hidden')
          continue
        }

        titleElement.innerHTML = titleElement.innerHTML.replace(regex, '<span class="highlight">$1</span>')
        textElement.innerHTML = textElement.innerHTML.replace(regex, '<span class="highlight">$1</span>')

        amountSearchResults++
      }
    }

    if (searchTerm.length > minSearchTextLength) {
      searchResults.value = !!amountSearchResults
      return
    }

    searchResults.value = true
  }

</script>
<style lang="scss">
.help-faq {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.app-accordion.hidden {
  display: none;
}
</style>
